







import { defineComponent, onUnmounted, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'YtVideo',
  props: {
    containerId: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    loop: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  setup: props => {
    const videoContainerId =
      props.containerId.trim().length > 0
        ? props.containerId
        : 'video-container';

    let player: YT.Player;

    const playerVars: YT.PlayerVars = {
      autoplay: 1, // Auto-play the video on load
      controls: 1, // Show pause/play buttons in player
      showinfo: 0, // Hide the video title
      modestbranding: 1, // Hide the Youtube Logo
      enablejsapi: 1,
      fs: 1, // Show the full screen button
      /* eslint-disable-next-line @typescript-eslint/camelcase */
      cc_load_policy: 0, // Hide closed captions
      /* eslint-disable-next-line @typescript-eslint/camelcase */
      iv_load_policy: 3, // Hide the Video Annotations
      // start: this.start,
      autohide: 1, // Hide video controls when playing
      rel: 0
      // end: this.stop
    };

    const videoPlaying = ref(false);
    // Create player at the end of the event queue
    const createPlayer = () => {
      setTimeout(() => {
        player = new YT.Player(videoContainerId, { // eslint-disable-line
          height: '100%',
          width: '100%',
          host: 'https://www.youtube-nocookie.com',
          videoId: props.id,
          playerVars: playerVars,
          events: {
            onReady: e => {
              player.mute();
              if (playerVars.autoplay) {
                e.target.playVideo();
              }
              player.setPlaybackQuality('hd720'); // eslint-disable-line
              videoPlaying.value = true;
            },
            onStateChange: state => {
              if (state.data === YT.PlayerState.ENDED) { // eslint-disable-line
                if (props.loop) {
                  player.loadVideoById({
                    videoId: props.id
                    // startSeconds: this.start,
                    // endSeconds: this.stop
                  });
                } else {
                  videoPlaying.value = false;
                }
              }
            }
          }
        });
      }, 0);
    };

    // If YT object already exists, use the existing one
    if (typeof YT === 'undefined' || typeof YT.Player === 'undefined') { // eslint-disable-line
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      tag.id = 'yt_iframe_api';

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);

      (window as Window &
        typeof globalThis & { onYouTubeIframeAPIReady: () => void })[ // eslint-disable-line
        'onYouTubeIframeAPIReady'
      ] = () => createPlayer();
    } else {
      createPlayer();
    }

    onUnmounted(() => player?.destroy());

    return {
      videoContainerId
    };
  }
});
