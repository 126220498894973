





















































































































































































































import { computed, defineComponent } from '@vue/composition-api';

import YtVideo from '@/components/YtVideo.vue';

export default defineComponent({
  name: 'ProfileJf',
  // props: {
  // },
  components: {
    YtVideo
  },
  setup: (props, ctx) => {
    const videoHeight = computed(() => {
      if (ctx.root.$vuetify.breakpoint.xs) {
        return '193px';
      }

      if (ctx.root.$vuetify.breakpoint.sm) {
        return `${ctx.root.$vuetify.breakpoint.height - 200}px`;
      }

      if (ctx.root.$vuetify.breakpoint.md) {
        return '558px';
      }

      return '720px';
    });

    return {
      videoHeight
    };
  }
});
